import { tv, VariantProps } from '@/utils/tv'

export const propertySearch = tv({
  slots: {
    base: [
      'w-full',
      'mx-auto',
      'rounded-lg',
      'bg-white shadow-md',
      'border border-gray-100',
      'overflow-hidden'
    ],
    form: ['flex flex-col lg:flex-row lg:items-center', 'w-full lg:h-20'],
    group: [
      'flex items-center',
      'w-full h-full',
      'flex-1',
      'p-3 lg:first:py-0',
      'lg:border-l border-gray-100 border-t lg:border-t-0',
      'lg:first:border-l-0 lg:last:border-l-0 first:border-t-0 last:border-t-0',
      'lg:last:max-w-44'
    ],
    select: ['p-0'],
    button: ['h-14']
  }
})

export type PropertySearchVariantProps = VariantProps<typeof propertySearch>
