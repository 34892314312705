import { tv, VariantProps } from '@/utils/tv'

export const select = tv({
  slots: {
    base: ['relative', 'group', 'flex flex-col', 'w-full'],
    label: ['mb-2'],
    group: ['relative', 'flex items-center gap-x-2'],
    select: [
      'block',
      'w-full',
      'rounded-md',
      'appearance-none',
      'outline-none',
      'cursor-pointer',
      'placeholder:text-gray-300'
    ],
    icon: ['flex pointer-events-none h-5 outline-none']
  },
  variants: {
    appearance: {
      outline: {
        select: [
          'border-gray-200',
          'bg-white',
          'focus:border-green-400 focus:ring focus:ring-green-100'
        ],
        icon: ['absolute inset-y-0 m-auto']
      },
      ghost: {
        select: [
          'px-0',
          'bg-transparent border-transparent',
          'focus:outline-none focus:border-transparent focus:ring-0'
        ]
      }
    },
    color: {
      dark: {
        icon: 'text-gray-400',
        label: 'text-gray-400'
      },
      light: ''
    },
    hasError: {
      true: ''
    },
    hasStartContent: {
      true: ''
    }
  },
  defaultVariants: {
    appearance: 'outline',
    color: 'dark',
    hasError: false
  },
  compoundVariants: [
    {
      appearance: 'outline',
      hasError: true,
      className: {
        select: ['border-red-500', 'focus:border-red-400 focus:ring-red-100']
      }
    },
    {
      appearance: 'outline',
      hasStartContent: true,
      className: {
        select: ['pl-10'],
        icon: []
      }
    }
  ],
  compoundSlots: [
    {
      slots: ['icon', 'label', 'select'],
      hasError: true,
      className: 'text-red-500'
    }
  ]
})

export type SelectVariantProps = Omit<
  VariantProps<typeof select>,
  'hasStartContent' | 'hasError'
>
