import { HTMLProps, useMemo } from 'react'
import { TextVariantProps, text } from './text.variants'

export type useTextProps = TextVariantProps & {
  as?:
    | 'p'
    | 'span'
    | 'small'
    | 'strong'
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'time'
    | 'cite'
    | 'q'
    | 'em'
    | 'label'
    | 'address'
  className?: string
  color?: string
  secondColor?: string
} & (
    | ({
        as: 'p'
      } & HTMLProps<HTMLParagraphElement>)
    | ({
        as: 'span'
      } & HTMLProps<HTMLSpanElement>)
    | ({
        as: 'small' | 'strong' | 'cite' | 'em'
      } & HTMLProps<HTMLElement>)
    | ({
        as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
      } & HTMLProps<HTMLHeadingElement>)
    | ({
        as: 'time'
      } & HTMLProps<HTMLTimeElement>)
    | ({
        as: 'q'
      } & HTMLProps<HTMLQuoteElement>)
    | ({
        as: 'label'
      } & HTMLProps<HTMLLabelElement>)
    | ({
        as: 'address'
      } & HTMLProps<HTMLTimeElement>)
  )

export const useText = (props: useTextProps) => {
  const {
    as = 'p',
    className = '',
    children,
    fontSize = 'body-lg',
    font,
    weight,
    uppercase = false,
    italic,
    notItalic,
    color,
    secondColor,
    ...componentProps
  } = props

  const styles = useMemo(
    () => text({ fontSize, font, weight, uppercase, italic, notItalic }),
    [fontSize, font, weight, uppercase, italic, notItalic]
  )

  return {
    Component: as,
    className,
    children,
    color,
    secondColor,
    styles,
    componentProps
  }
}
