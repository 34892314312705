import { forwardRef } from 'react'
import { Label } from '@/components/ui/label'
import { HelpText } from '@/components/ui/help-text'
import { useSelect, useSelectProps } from './use-select'

export type SelectProps = useSelectProps

const Select = forwardRef<HTMLSelectElement, SelectProps>((props, ref) => {
  const {
    className,
    inputClassName,
    fieldId,
    getOptions,
    label,
    hideLabel,
    hasError,
    startContent,
    errorMessage,
    styles,
    isGhost,
    componentProps
  } = useSelect({ ...props, ref })

  return (
    <div className={styles.base({ className })}>
      {!!label && (
        <Label
          htmlFor={fieldId}
          className={styles.label()}
          size={isGhost ? 'sm' : 'md'}
          weight={isGhost ? 'medium' : 'semibold'}
          hidden={hideLabel}
        >
          {label}
        </Label>
      )}
      <div className={styles.group()}>
        {!!startContent && (
          <span className={styles.icon({ className: 'left-3' })}>
            {startContent}
          </span>
        )}
        <select
          id={fieldId}
          className={styles.select({ className: inputClassName })}
          {...componentProps}
        >
          {getOptions()}
        </select>
      </div>
      {hasError && <HelpText status="danger">{errorMessage}</HelpText>}
    </div>
  )
})

Select.displayName = 'Select'

export default Select
