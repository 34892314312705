import { tv, VariantProps } from '@/utils/tv'

export const helpText = tv({
  slots: {
    base: ['flex gap-1.5', 'text-body-sm'],
    icon: ['w-2.5 h-2.5', 'mt-1', 'outline-none']
  },
  variants: {
    status: {
      default: {
        base: ''
      },
      success: {
        base: 'text-garden-500'
      },
      danger: {
        base: 'text-red-500'
      }
    },
    floating: {
      false: 'mt-1',
      true: 'absolute top-full translate-y-0.5'
    }
  },
  defaultVariants: {
    status: 'default',
    floating: true
  }
})

export type HelpTextVariantProps = VariantProps<typeof helpText>
