import { tv, VariantProps } from '@/utils/tv'

export const toggle = tv({
  slots: {
    base: ['inline-flex group'],
    items: [
      'flex items-center',
      'p-1.5',
      'border border-gray-200',
      'rounded-full'
    ],
    group: ['flex items-center'],
    input: ['sr-only peer'],
    label: [
      'cursor-pointer',
      'text-center',
      'h-12',
      'py-3 px-4',
      'leading-tight',
      'flex items-center justify-center',
      'rounded-full',
      'overflow-hidden',
      'border border-transparent',
      'peer-focus:outline-none',
      'peer-checked:border-green-500 peer-checked:bg-green-500 peer-checked:text-white'
    ]
  },
  variants: {
    color: {
      dark: {
        items: ['bg-gray-10'],
        label: 'text-gray-400'
      },
      light: {
        label: 'text-white'
      }
    },
    fullWidth: {
      true: {
        base: ['w-full'],
        items: ['flex-1'],
        group: ['flex-1'],
        label: ['w-full']
      }
    },
    hasError: {
      true: ''
    }
  },
  defaultVariants: {
    color: 'dark',
    hasError: false
  }
})

export type SwitchVariantProps = Omit<VariantProps<typeof toggle>, 'hasError'>
