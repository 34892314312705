'use client'

import { Select } from '@/components/data/select'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/data/switch'
import {
  type UsePropertySearchProps,
  usePropertySearch
} from './use-property-search'

export type PropertySearchProps = UsePropertySearchProps

const PropertySearch = (props: PropertySearchProps) => {
  const { styles, lang, filters, register, formState, onSubmit } =
    usePropertySearch(props)

  const {
    locations,
    taxonomies: { building_finality, building_type }
  } = filters

  return (
    <div className={styles.base()}>
      <form noValidate onSubmit={onSubmit} className={styles.form()}>
        <div className={styles.group()}>
          <Switch
            fullWidth
            items={building_finality.map(({ slug }) => ({
              ...register('finalidade'),
              label: lang[slug],
              value: slug
            }))}
          />
        </div>
        <div className={styles.group()}>
          <Select
            {...register('tipo')}
            appearance="ghost"
            label={lang.type}
            placeholder={lang.all_properties}
            inputClassName={styles.select()}
            options={building_type.map(({ slug, title }) => ({
              label: title,
              value: slug
            }))}
          />
        </div>
        <div className={styles.group()}>
          <Select
            {...register('localizacao')}
            appearance="ghost"
            label={lang.location}
            placeholder={lang.all_locations}
            className={styles.select()}
            inputClassName={styles.select()}
            options={locations.map(({ slug, title }) => ({
              label: title,
              value: slug
            }))}
          />
        </div>
        <div className={styles.group()}>
          <Button
            type="submit"
            variant="solid"
            color="secondary"
            size="lg"
            fullWidth
            endIcon="search"
            loading={formState.isSubmitting}
            loadingLabel={lang.search}
            className={styles.button()}
          >
            {lang.search}
          </Button>
        </div>
      </form>
    </div>
  )
}

export default PropertySearch
