'use client'

import { Children } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { A11y, Autoplay } from 'swiper/modules'
import { useCarousel, useCarouselProps } from './use-carousel'

export type CarouselProps = useCarouselProps

const Carousel = (props: useCarouselProps) => {
  const { swiperRef, styles, className, children, opts } = useCarousel(props)

  return (
    <Swiper
      ref={swiperRef}
      className={styles.base({ className: `${className} w-full` })}
      modules={[A11y, Autoplay]}
      {...opts}
    >
      {Children.map(children, (child) => {
        return <SwiperSlide className={styles.slide()}>{child}</SwiperSlide>
      })}
    </Swiper>
  )
}

export default Carousel
