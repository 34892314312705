import { InputHTMLAttributes, useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import { SwitchVariantProps, toggle } from './switch.variants'

type SwitchLabel = {
  label?: string
} & InputHTMLAttributes<HTMLInputElement>

export type useSwitchProps = SwitchVariantProps & {
  items: SwitchLabel[]
  className?: string
  error?: FieldError
}

export const useSwitch = (props: useSwitchProps) => {
  const {
    className = '',
    items = [],
    fullWidth = false,
    color = 'dark',
    error
  } = props
  const hasError = !!error

  const styles = useMemo(
    () => toggle({ color, fullWidth, hasError }),
    [color, fullWidth, hasError]
  )

  return {
    className,
    items,
    hasError,
    errorMessage: error?.message,
    styles
  }
}
