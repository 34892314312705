import { useMemo } from 'react'
import { SvgName } from '@/svg'
import { ServiceCardVariantProps, serviceCard } from './service-card.variants'

export type useServiceCardProps = ServiceCardVariantProps & {
  className?: string
  icon: SvgName
  title: string
  description?: string
  cta?: string
  url: string
  onClick?: () => void
}

export const useServiceCard = (props: useServiceCardProps) => {
  const {
    className = '',
    variant = 'medium',
    onClick = () => {},
    ...componentProps
  } = props

  const styles = useMemo(() => serviceCard({ variant }), [variant])

  const isTiny = variant === 'tiny'

  return { styles, className, isTiny, data: { ...componentProps, onClick } }
}
