import { useSwitch, useSwitchProps } from './use-switch'
import { HelpText } from '@/components/ui/help-text'
import { Label } from '@/components/ui/label'

export type SwitchProps = useSwitchProps

const Switch = (props: SwitchProps) => {
  const { className, items, hasError, errorMessage, styles } = useSwitch({
    ...props
  })

  return (
    <div className={styles.base({ className })}>
      <div className={styles.items()}>
        {items.map(({ id, name, label, ...rest }, i) => {
          const fieldId = `${id || name}-${i}`

          return (
            <div key={fieldId} className={styles.group()}>
              <input
                id={fieldId}
                className={styles.input()}
                name={name}
                {...rest}
                type="radio"
              />
              {!!label && (
                <Label htmlFor={fieldId} size="md" className={styles.label()}>
                  {label}
                </Label>
              )}
            </div>
          )
        })}
      </div>
      {hasError && <HelpText status="danger">{errorMessage}</HelpText>}
    </div>
  )
}

Switch.displayName = 'Switch'

export default Switch
