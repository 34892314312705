import { VariantProps, tv } from '@/utils/tv'

export const carousel = tv({
  slots: {
    base: [],
    slide: []
  },
  variants: {
    full: {
      false: {},
      true: {
        slide: ['w-full !h-auto', '[&>*]:h-full']
      }
    }
  },
  defaultVariants: {
    full: true
  }
})

export type CarouselVariantProps = VariantProps<typeof carousel>
