'use client'

import { ForwardedRef, forwardRef } from 'react'
import { NextLink } from '@/utils/navigation'
import { Vector } from '@/components/ui/vector'
import { Text } from '@/components/ui/text'
import { useServiceCard, useServiceCardProps } from './use-service-card'

export type ServiceCardProps = useServiceCardProps

const ServiceCard = forwardRef((props: ServiceCardProps, ref) => {
  const { className, styles, isTiny, data } = useServiceCard(props)
  const { icon, title, description, cta, url, onClick } = data

  return (
    <div className={styles.base({ className })}>
      <div className={styles.content()}>
        <NextLink
          ref={ref as ForwardedRef<HTMLAnchorElement>}
          className={styles.link()}
          title={title}
          href={url}
          onClick={onClick}
        />
        <div className={styles.header()}>
          <Vector name={icon} className={styles.icon()} />
          <Text
            as="h3"
            className={styles.title()}
            fontSize="body-md"
            weight="semibold"
          >
            {title}
          </Text>
          {!isTiny && !!description && (
            <Text as="p" className={styles.description()} color="text-gray-400">
              {description}
            </Text>
          )}
        </div>
        {!isTiny && !!cta && (
          <div className={styles.footer()}>
            <Text
              as="p"
              font="rubik"
              weight="semibold"
              fontSize="body-md"
              color="text-green-500"
              className={styles.cta()}
            >
              {cta}
              <Vector name="arrow-right" className={styles.arrow()} />
            </Text>
          </div>
        )}
      </div>
    </div>
  )
})

ServiceCard.displayName = 'ServiceCard'

export default ServiceCard
