import { useMemo, useRef } from 'react'
import type { SwiperOptions } from 'swiper/types'
import { CarouselVariantProps, carousel } from './carousel.variants'

export type useCarouselProps = CarouselVariantProps & {
  className?: string
  children?: React.ReactNode
  opts?: SwiperOptions
}

export const useCarousel = (props: useCarouselProps) => {
  const { className = '', children, full = true, opts } = props

  const swiperRef = useRef(null)
  const styles = useMemo(() => carousel({ full }), [full])

  return {
    swiperRef,
    styles,
    className,
    opts,
    children
  }
}
