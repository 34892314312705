import { Vector } from '@/components/ui/vector'
import { useHelpText, useHelpTextProps } from './use-help-text'

export type HelpTextProps = useHelpTextProps

const HelpText = (props: HelpTextProps) => {
  const { className, children, status, styles, componentProps } =
    useHelpText(props)

  return (
    <span className={styles.base({ className })} {...componentProps}>
      {status === 'danger' && <Vector name="error" className={styles.icon()} />}
      {children}
    </span>
  )
}

export default HelpText
