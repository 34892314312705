import { SelectHTMLAttributes, ForwardedRef, useMemo } from 'react'
import { FieldError } from 'react-hook-form'
import { SvgName } from '@/svg'
import { SelectVariantProps, select } from './select.variants'

type OptionProps =
  | string
  | number
  | { value: string | number; label: string | number; disabled?: boolean }

export type useSelectProps = SelectVariantProps & {
  ref: ForwardedRef<HTMLSelectElement>
  className?: string
  inputClassName?: string
  options: OptionProps[]
  label?: string
  placeholder?: string
  description?: string
  startContent?: React.ReactNode | SvgName
  error?: FieldError
  hideLabel?: boolean
  hidePlaceholder?: boolean
} & SelectHTMLAttributes<HTMLSelectElement>

export const useSelect = (props: useSelectProps) => {
  const {
    ref,
    className = '',
    inputClassName = '',
    id,
    name,
    options = [],
    description,
    placeholder = 'Selecione',
    label,
    hideLabel = false,
    hidePlaceholder = false,
    appearance = 'outline',
    startContent,
    error,
    ...componentProps
  } = props

  const fieldId = id || name
  const hasError = !!error
  const isGhost = appearance === 'ghost'

  const styles = useMemo(
    () =>
      select({
        appearance,
        hasStartContent: !!startContent,
        hasError
      }),
    [appearance, startContent, hasError]
  )

  const getOptions = () => {
    const opts = options.map((option) => {
      if (typeof option === 'object') {
        return (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        )
      }

      return (
        <option key={option} value={option}>
          {option}
        </option>
      )
    })

    if (!hideLabel) {
      opts.unshift(
        <option
          key="placeholder"
          value=""
          className="placeholder:text-gray-300"
        >
          {hideLabel ? label : placeholder}
        </option>
      )
    }

    return opts
  }

  return {
    fieldId,
    className,
    inputClassName,
    getOptions,
    description,
    label,
    hideLabel,
    hasError,
    startContent,
    errorMessage: error?.message,
    isGhost,
    styles,
    componentProps: {
      ...componentProps,
      ref,
      name
    }
  }
}
