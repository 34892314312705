import { tv, VariantProps } from '@/utils/tv'

export const serviceCard = tv({
  slots: {
    base: [
      'relative',
      'group/item',
      'rounded-md',
      'overflow-hidden',
      'card-gradient',
      'w-full',
      'select-all',
      'before:rounded-md after:rounded-md'
    ],
    content: [
      'flex flex-col justify-between',
      'relative z-20',
      'w-full h-full'
    ],
    link: ['absolute inset-0 z-10', 'w-full h-full'],
    icon: ['text-green-500'],
    header: ['flex mb-auto'],
    title: [
      'transition-colors duration-300',
      'group-hover/item:text-green-500'
    ],
    description: ['text-body-md', 'mt-1.5'],
    footer: [],
    cta: ['flex items-center gap-2', 'mt-10'],
    arrow: ['w-4 h-4']
  },
  variants: {
    variant: {
      tiny: {
        base: ['p-4'],
        header: ['flex-row items-center'],
        icon: ['w-5 h-5 mr-3']
      },
      medium: {
        base: ['p-6'],
        header: ['flex-col'],
        icon: ['w-7 h-7 mb-4']
      },
      huge: {
        base: ['p-8', 'min-h-[320px]'],
        header: ['flex-col'],
        icon: ['w-9 h-9 mb-4'],
        title: ['text-body-xl']
      }
    }
  },
  defaultVariants: {
    variant: 'medium'
  }
})

export type ServiceCardVariantProps = VariantProps<typeof serviceCard>
