import { useForm, SubmitHandler } from 'react-hook-form'
import { useRouter } from '@/utils/navigation'
import { type CockpitPropertyFiltersOperation } from '@/services/types'
import { type SearchSchema } from '@/types'
import { propertySearch } from './property-search.variants'

export type UsePropertySearchProps = {
  lang: Record<string, string>
  filters: CockpitPropertyFiltersOperation
}

export const usePropertySearch = (props: UsePropertySearchProps) => {
  const { lang, filters } = props

  const router = useRouter()

  const styles = propertySearch()

  const { register, handleSubmit, formState } = useForm<SearchSchema>({
    defaultValues: {
      finalidade: 'alugar'
    }
  })

  const onSubmit: SubmitHandler<SearchSchema> = async ({
    finalidade,
    tipo,
    localizacao
  }) => {
    try {
      const [cidade, bairro] = localizacao.split('_')

      const filteredParams = Object.fromEntries(
        Object.entries({
          finalidade,
          tipo,
          cidade,
          bairro
        }).filter(([_, value]) => value != null && value !== '')
      )

      const searchParams = new URLSearchParams(filteredParams)

      router.push(`/imoveis?${searchParams.toString()}`)
    } catch (error) {
      console.error('Error on search', error)
    }
  }

  return {
    styles,
    lang,
    register,
    onSubmit: handleSubmit(onSubmit),
    filters,
    formState
  }
}
