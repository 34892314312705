import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/frontend/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/common/carousel/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/common/gallery/gallery.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/features/properties/favorite-toggle/favorite-toggle.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PropertyCardActions"] */ "/vercel/path0/frontend/src/components/features/properties/property-card/property-card.actions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/features/properties/property-search/property-search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/features/services/service-card/service-card.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/ui/button/button.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/ui/link/link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/frontend/src/components/ui/vector/vector.tsx");
